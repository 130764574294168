import get from "lodash/get.js";

const parseImageFilesToDict = (files, alias = "childImageSharp") => {
  let frameFrontShots = {};
  files.edges.forEach((e) => {
    let node = e.node;
    let dir = node.relativeDirectory;
    let img = node[alias];
    let url = node.publicURL;
    let [sku, frame, lens] = parseSKUFromProductImage(dir);
    frameFrontShots[`${sku}-${frame}-${lens}`] = {
      img: img,
      url: url,
    };
  });
  return frameFrontShots;
};

const getCustomImgKey = (spec) => {
  const color = spec.selectedFrameColor.name.en.replace(" ", "-");
  const lens = spec.selectedLens.name.en.replace(" ", "-");
  return `${spec.sku}-${color}-${lens}`;
};

const getShopifyImageKey = (bagFrame) => {
  const { selectedOptions } = bagFrame.variant;
  const color = selectedOptions
    .find((o) => o.name === "Color")
    .value.replace(" ", "-");
  const lens = selectedOptions
    .find((o) => o.name === "Lens")
    .value.replace(" ", "-");
  return `${nameToSkuType(bagFrame.title)}-${color}-${lens}`;
};

const getVariantFromCurStyle = (
  curStyleID,
  curStyle,
  curSize,
  allShopifyProductVariant
) => {
  if (curStyle) {
    var curVariant = allShopifyProductVariant.edges.find((edge) => {
      let variant = edge.node;
      const [
        lensID,
        colorID,
        isPrescription,
        styleID,
        size,
      ] = variant.sku.split("|");
      let variantColor = variant.selectedOptions.find((o) => o.name === "Color")
        .value;
      let variantLens = variant.selectedOptions.find((o) => o.name === "Lens")
        .value;
      let variantSize = variant.selectedOptions.find((o) => o.name === "Size")
        .value;
      return (
        styleID === curStyle.design.kcID &&
        variantLens === curStyle.lens.name.replace("Zeiss ", "") &&
        variantColor === curStyle.color.name &&
        variantSize === curSize
      );
    });
    if (curVariant) {
      return curVariant.node;
    }
  }
  return undefined;
};

const getVariantFromCurFrame = (curFrame, allShopifyProductVariant) => {
  if (curFrame) {
    var curVariant = allShopifyProductVariant.edges.find((edge) => {
      let variant = edge.node;
      let variantColor = variant.selectedOptions.find((o) => o.name === "Color")
        .value;
      let variantLens = variant.selectedOptions.find((o) => o.name === "Lens")
        .value;
      let variantSize = variant.selectedOptions.find((o) => o.name === "Size")
        .value;
      return (
        variantLens === curFrame.selectedLens.name.en &&
        variantColor === curFrame.selectedFrameColor.name.en &&
        variantSize === curFrame.selectedSize
      );
    });
    if (curVariant) {
      return curVariant.node;
    }
  }
  return undefined;
};

const parseSKUFromProductImage = (relativeDir) => {
  // given the relative directory of an image file in /Product directory
  // returns sku, frame and lens
  let [, sku, frame, lens] = relativeDir.split("/");
  return [sku, frame, lens];
};

const defaultLang = "en";
const langLookup = (langObj, lang) => {
  return lang in langObj ? langObj[lang] : get(langObj, [defaultLang], "");
};

const nameToSkuType = (name) => {
  return name.toLowerCase().replace(/\s+/g, "");
};

const fetchGeolocation = async (lensId) => {
  let data = await fetch(`${process.env.GATSBY_KC_ENDPOINT}/geo`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((e) => {
      return {
        data: {
          currency: "USD",
          country: "United States",
        },
      };
    });
  return get(data, `data`, {});
};

const fetchInventory = async (lensId) => {
  let data = await fetch(
    `${process.env.GATSBY_KC_ENDPOINT}/pd_inventory?id=${lensId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((e) => {
      // TODO ERROR HANDLING
    });
  return get(data, `data.stock`, undefined);
};

const getImageKey = (sku, spec) => {
  const color = spec.selectedFrameColor.name.en.replace(" ", "-");
  const lens = spec.selectedLens.name.en.replace(" ", "-");
  return `${sku}-${color}-${lens}`;
};

const parsePrice = (price) => {
  return parseFloat(price).toLocaleString("en-us", {
    minimumFractionDigits: 2,
  });
};

export {
  fetchGeolocation,
  fetchInventory,
  getCustomImgKey,
  getShopifyImageKey,
  getImageKey,
  getVariantFromCurStyle,
  getVariantFromCurFrame,
  langLookup,
  nameToSkuType,
  parseImageFilesToDict,
  parseSKUFromProductImage,
  parsePrice,
};
