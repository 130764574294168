exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-kc-tv-js": () => import("./../../../src/pages/kcTV.js" /* webpackChunkName: "component---src-pages-kc-tv-js" */),
  "component---src-pages-king-children-install-app-js": () => import("./../../../src/pages/king-children/installApp.js" /* webpackChunkName: "component---src-pages-king-children-install-app-js" */),
  "component---src-pages-king-children-js": () => import("./../../../src/pages/king-children.js" /* webpackChunkName: "component---src-pages-king-children-js" */),
  "component---src-pages-king-children-sustainability-js": () => import("./../../../src/pages/king-children/sustainability.js" /* webpackChunkName: "component---src-pages-king-children-sustainability-js" */),
  "component---src-pages-order-confirmed-js": () => import("./../../../src/pages/orderConfirmed.js" /* webpackChunkName: "component---src-pages-order-confirmed-js" */),
  "component---src-pages-order-tracking-js": () => import("./../../../src/pages/orderTracking.js" /* webpackChunkName: "component---src-pages-order-tracking-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-segment-js": () => import("./../../../src/pages/segment.js" /* webpackChunkName: "component---src-pages-segment-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-collection-template-js": () => import("./../../../src/templates/collectionTemplate.js" /* webpackChunkName: "component---src-templates-collection-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-product-page-template-js": () => import("./../../../src/templates/productPageTemplate.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */)
}

