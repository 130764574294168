import React from "react";

import { nameToSkuType } from "../utils";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case "SET_EMAIL": {
      return {
        ...state,
        email: action.email,
      };
    }

    case "SET_SHOPIFY_CHECKOUT": {
      return {
        ...state,
        shopifyCheckout: action.shopifyCheckout,
      };
    }
    case "INIT_CUR_FRAMES": {
      return {
        ...state,
        curFrames: action.frames,
      };
    }

    case "UPDATE_CUR_FRAME_SKU": {
      return {
        ...state,
        curSKU: action.sku,
        curFrames: {
          ...state.curFrames,
          [action.sku]: {
            ...(state.curFrames && state.curFrames[action.sku]),
            selectedFrameColor: action.color,
            selectedLens: action.lens,
          },
        },
      };
    }

    case "UPDATE_CUR_FRAMES_COLOR": {
      return {
        ...state,
        curSKU: action.sku,
        curFrames: {
          ...state.curFrames,
          [action.sku]: {
            ...state.curFrames[action.sku],
            selectedFrameColor: action.color,
          },
        },
      };
    }

    case "UPDATE_CUR_FRAME_SIZE": {
      return {
        ...state,
        isInstallPopupShown: action.size === "Custom",
        curSKU: action.sku,
        curFrames: {
          ...state.curFrames,
          [action.sku]: {
            ...state.curFrames[action.sku],
            selectedSize: action.size,
          },
        },
      };
    }

    case "UPDATE_CUR_FRAME_LENS": {
      let curColor = action.lens.frameColors.find(
        (c) => c.hex === state.curFrames[action.sku].selectedFrameColor.hex
      )
        ? state.curFrames[action.sku].selectedFrameColor
        : action.lens.frameColors[0];

      return {
        ...state,
        curSKU: action.sku,
        curFrames: {
          ...state.curFrames,
          [action.sku]: {
            ...state.curFrames[action.sku],
            selectedFrameColor: curColor,
            selectedLens: action.lens,
          },
        },
      };
    }

    case "SET_CUR_SKU": {
      return {
        ...state,
        curSKU: action.sku,
      };
    }

    case "SET_STOCK": {
      return {
        ...state,
        stock: {
          ...state.stock,
          [action.sku]: action.stock,
        },
      };
    }

    case "REMOVE_MESSAGE_MODAL": {
      return {
        ...state,
        closedMessageModals: [...state.closedMessageModals, action.modal],
      };
    }

    case "SET_EMAIL_POPUP_SHOWN": {
      return {
        ...state,
        hasEmailPopupShown: state.hasEmailPopupShown || action.isShown,
        isEmailPopupShown: action.isShown,
      };
    }

    case "SET_INSTALL_POPUP_SHOWN": {
      return {
        ...state,
        isInstallPopupShown: action.isShown,
      };
    }

    case "SET_SIZE_POPUP_SHOWN": {
      return {
        ...state,
        isSizePopupShown: action.isShown,
      };
    }

    case "SET_IS_MOBILE_NAV_SHOWN": {
      return {
        ...state,
        isMobileNavShown: action.isShown,
      };
    }

    case "SET_CUR_LINE": {
      return {
        ...state,
        curLine: action.line,
      };
    }

    case "SET_INSTALL_BANNER_SHOWN": {
      return {
        ...state,
        isInstallBannerShown: action.isShown,
        hasInstallBannerShown: state.hasInstallBannerShown || action.isShown,
      };
    }

    case "SET_IS_BUY_POPUP_SHOWN": {
      return {
        ...state,
        isBuyPopupShown: action.isShown,
      };
    }

    case "TOGGLE_IS_BAG_SHOWN": {
      return {
        ...state,
        isBuyPopupShown: !state.isBuyPopupShown,
      };
    }

    case "ADD_TO_BAG": {
      return {
        ...state,
        isBuyPopupShown: true,
        shopifyCheckout: action.shopifyCheckout,
      };
    }

    case "UPDATE_BAG": {
      return {
        ...state,
        shopifyCheckout: action.shopifyCheckout,
      };
    }

    case "SET_IS_INTL_POPUP_SHOWN": {
      return {
        ...state,
        isIntlPopupShown: action.isShown,
      };
    }

    case "SET_CURRENCY": {
      return {
        ...state,
        currency: action.currency,
        currencyCountry: action.country,
      };
    }

    default:
      return state;
  }
}

const initialState = {
  email: undefined,
  shopifyCheckout: undefined,
  isMobileNavShown: false,
  isInstallPopupShown: false,
  isSizePopupShown: false,
  hasEmailPopupShown: false,
  isEmailPopupShown: false,
  isInstallBannerShown: false,
  hasInstallBannerShown: false,
  curSKU: "pd10performancedistressed",
  curLine: "PD",
  isBuyPopupShown: false,
  isIntlPopupShown: false,
  curFrames: null,
  currencyCountry: null,
  currency: null,
  stock: {},
  closedMessageModals: [],
};

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const initShopifyCheckout = () => {};

export default GlobalContextProvider;
